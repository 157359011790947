
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { isNull } from 'lodash';
import { connect } from 'react-redux';
import UserProfileMain from './index';
import { Waiting } from '../../components';


class UserProfile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, userId, companyId, history} = this.props;
    if (_.isUndefined(user) || _.isNull(user) || _,isNull(userId) ) {
      return <Waiting />
    }
    return (
      <UserProfileMain companyId={companyId} history={history}/>
    );
  }
}
    
UserProfile.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.number,
  companyId: PropTypes.number,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.profileData.user,
  userId: state.profileData.userId
})
 
export default connect(mapStateToProps, null)(((UserProfile)));